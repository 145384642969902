import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardUser} from '../Shared-global/Guards/auth-guard-user';
import {AuthGuardAdmin} from '../Shared-global/Guards/auth-guard-admin';
import {SubscriptionModule} from '../subscription/subscription.module';

const routes: Routes = [

    {path: 'login', loadChildren: () => import('../login/login.module').then(m => m.LoginModule)},
    {path: 'public', loadChildren: () => import('../public/public.module').then(m => m.PublicModule)},
    {path: 'devices', loadChildren: () => import('../device/device.module').then(m => m.DeviceModule), canActivate: [AuthGuardUser]},
    {path: 'deviceprize', loadChildren: () => import('../deviceprize/deviceprize.module').then(m => m.DevicePrizeModule), canActivate: [AuthGuardUser]},
    {path: 'customers', loadChildren: () => import('../customer/customer.module').then(m => m.CustomerModule), canActivate: [AuthGuardUser]},
    {path: 'products', loadChildren: () => import('../product/product.module').then(m => m.ProductModule), canActivate: [AuthGuardUser]},
    {path: 'stickers', loadChildren: () => import('../sticker/sticker.module').then(m => m.StickerModule), canActivate: [AuthGuardAdmin]},
    {path: 'orders', loadChildren: () => import('../mobilepayorder/mobilepayorder.module').then(m => m.MobilepayorderModule), canActivate: [AuthGuardUser]},
    {path: 'loyalty', loadChildren: () => import('../loyalty/loyalty.module').then(m => m.LoyaltyModule), canActivate: [AuthGuardUser]},
    {path: 'mifare', loadChildren: () => import('../mifare/mifare.module').then(m => m.MifareModule), canActivate: [AuthGuardUser]},
    {path: 'aarskort', loadChildren: () => import('../aarskort/aarskort.module').then(m => m.AarskortModule), canActivate: [AuthGuardUser]},
    {path: 'club', loadChildren: () => import('../club/club.module').then(m => m.ClubModule), canActivate: [AuthGuardUser]},
    {path: 'payter', loadChildren: () => import('../payter/payter.module').then(m => m.PayterModule), canActivate: [AuthGuardAdmin]},
    {path: 'integration', loadChildren: () => import('../integration/integration.module').then(m => m.IntegrationModule), canActivate: [AuthGuardUser]},
    {path: 'health', loadChildren: () => import('../health/health.module').then(m => m.HealthModule), canActivate: [AuthGuardAdmin]},
    {path: 'admin', loadChildren: () => import('../admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuardUser]},
    {path: 'vouchers', loadChildren: () => import('../vouchers/vouchers.module').then(m => m.VouchersModule), canActivate: [AuthGuardUser]},
    {path: 'vouchertypes', loadChildren: () => import('../vouchertypes/vouchertypes.module').then(m => m.VouchertypesModule), canActivate: [AuthGuardAdmin]},
    {path: 'translations', loadChildren: () => import('../translations/translations.module').then(m => m.TranslationsModule), canActivate: [AuthGuardAdmin]},
    {path: 'measuring', loadChildren: () => import('../measuring/measuring.module').then(m => m.MeasuringModule), canActivate: [AuthGuardUser]},
    {path: 'config', loadChildren: () => import('../config/config.module').then(m => m.ConfigModule), canActivate: [AuthGuardUser]},
    {path: 'options', loadChildren: () => import('../dashboard/dashboard-routing/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuardUser]},
    {path: 'sales', loadChildren: () => import('../dashboard/dashboard-routing/sales.module').then(m => m.SalesModule), canActivate: [AuthGuardUser]},
    {path: 'bookkeeping', loadChildren: () => import('../dashboard/dashboard-routing/bookkeeping.module').then(m => m.BookkeepingModule), canActivate: [AuthGuardUser]},
    {path: 'service', loadChildren: () => import('../service/service.module').then(m => m.ServiceModule)},
    {path: 'apiintegration', loadChildren: () => import('../appintegration/appintegration.module').then(m => m.AppIntegrationModule), canActivate: [AuthGuardUser]},
    {path: 'alerts', loadChildren: () => import('../alert/alert.module').then(m => m.AlertModule), canActivate: [AuthGuardUser]},
    {path: 'subscriptions', loadChildren: () => import('../subscription/subscription.module').then(m => m.SubscriptionModule), canActivate: [AuthGuardAdmin]},
    {path: '', redirectTo: 'devices', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        // { enableTracing: true } // <-- debugging purposes only
    )],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
